import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="container">
        <div className="text">personas , perspectives , people , pages , </div>
        <div className="title">nothing is ever wrong</div>
        <div className="links">
          <a href="https://www.instagram.com/n0thingiseverwrong/">IG</a>
          <a href="https://x.com/iseverwrong">X</a>
          <a href="https://www.youtube.com/@nothingiseverwrong">YT</a>
        </div>
      </div>
    </div>
  );
}

export default App;
